import App from './app.js';
import { findSimpleElements, renderSimpleElements } from './simple.js';
import { createTracker, trackPageView } from './lib/tracking.js';

// google analytics
// identify();
createTracker('UA-38348457-14');
trackPageView();

let app = new App({
  product: {
    buttonDestination:      'cart',
  },
  productSet: {
    contents: {
      // FIXME: workaround for https://github.com/Shopify/buy-button-js/issues/454
      pagination:           false,
    },
  },
  cart: {
    startOpen:              false,
    popup:                  false,
  },
});

// Simple mode
let elements = findSimpleElements(document, [ '.stampr-marketplace', 'stampr-product', 'stampr-productset', 'stampr-collection' ]);
if (elements.length > 0) {
  // run simple mode
  // console.info('running in simple mode');
  // render on next tick to allow cache injection
  setTimeout(() => renderSimpleElements(app, elements), 0);
}

app.injectData = ShopifyBuy.injectData;

export default app;
