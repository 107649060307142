import clone from 'clone';
import deepAssign from 'deep-assign';

export default class Configuration {
  constructor(options = {}) {
    this._options = clone(options);
  }

  import(options = {}) {
    deepAssign(this._options, clone(options));
  }

  clone(options = null) {
    let configuration = new Configuration(this._options);
    if (options) {
      configuration.import(options);
    }
    return configuration;
  }

  export(options) {
    return this.clone(options)._options;
  }
}
