
// NOTE: always attempt gtag first since it looks like gtag also creates a ga global

// disable for now.  there is some weirdness with double
// attempts and who cares, ga is supported
const ATTEMPT_GTAG_SUPPORT = false;

export const MARKETPLACE_TRACKER = 'stamprMarketplace';

export const TRACK_UNSUCCESSFUL = ['track_unsuccessful'];

export function identify() {
  try {
    let identification;
    if (window.gtag) {
      identification = 'gtag';
    }
    else if (window.ga) {
      identification = 'ga';
    }
    else {
      identification = 'unknown';
    }
    // console.debug('analytics identification:', identification);
  }
  catch(err){}
}

export function ga(...args) {
  try {
    if (window.ga) {
      // console.log('running ga');
      return window.ga(...args);
    }
    else {
      return TRACK_UNSUCCESSFUL;
    }
  }
  catch (err) {
    // console.debug('ga error', err.stack || err);
    return TRACK_UNSUCCESSFUL;
  }
}

export function gtag(...args) {
  if (ATTEMPT_GTAG_SUPPORT) {
    try {
      if (window.gtag) {
        // console.log('running gtag');
        return window.gtag(...args);
      }
      else {
        return TRACK_UNSUCCESSFUL;
      }
    }
    catch (err) {
      // console.debug('gtag error', err.stack || err);
      return TRACK_UNSUCCESSFUL;
    }
  }
  else {
    return TRACK_UNSUCCESSFUL;
  }
}

export function createTracker(trackingId) {
  let gtagResult = createTracker_gtag(trackingId);
  // console.log('createTracker gtagResult', gtagResult);
  if (TRACK_UNSUCCESSFUL === gtagResult) {
    // console.log('TRACK_UNSUCCESSFUL; running createTracker_ga');
    createTracker_ga(trackingId);
  }
}

export function createTracker_gtag(trackingId) {
  return gtag('config', trackingId);
}

export function createTracker_ga(trackingId) {
  return ga('create', trackingId, 'auto', MARKETPLACE_TRACKER);
}

export function trackPageView() {
  ga(`${MARKETPLACE_TRACKER}.send`, 'pageview');
  // gtag is done automatically, so no need to juggle
}

export function trackEvent({ category, action, label, value }) {
  let gtagResult = trackEvent_gtag(category, action, label, value);
  // console.log('trackEvent gtagResult', gtagResult);
  if (TRACK_UNSUCCESSFUL === gtagResult) {
    // console.log('TRACK_UNSUCCESSFUL; running trackEvent_ga');
    trackEvent_ga(category, action, label, value);
  }
}

export function trackEvent_gtag(category, action, label, value) {
  // console.log('attempting trackEvent_gtag', arguments);
  return gtag('event', action, {
    event_category:   category,
    event_label:      label,
    value,
  });
}

export function trackEvent_ga(category, action, label, value) {
  // console.log('attempting trackEvent_ga', arguments);
  if (TRACK_UNSUCCESSFUL !== ga('send', 'event', category, action, label, value)) {
    // console.log('success; doing second event', arguments);
    // send was successful, track the other event and return so gtag isn't attempted
    return ga(`${MARKETPLACE_TRACKER}.send`, 'event', category, action, label, value);
  }
}
