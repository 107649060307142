import deepAssign from 'deep-assign';

import Component from './models/component.js';
import Configuration from './models/configuration.js';

import { trackEvent } from './lib/tracking.js';

import { version } from '../package.json';

export default class App {
  constructor(options = {}) {
    this.configuration  = new Configuration(options);
    this._configured    = false;
    this._components    = {};
    this.configuration._options.toggle        = this.configuration._options.toggle || {};
    this.configuration._options.toggle.events = this.configuration._options.toggle.events || {};
    // work around not being able to hide toggle
    this.configuration._options.toggle.events.afterRender = component => {
      this._removeToggleCartButton();
    };
    this._renderedElements = [];
  }

  get version() {
    return version;
  }

  _trackEvent(category, action, label, value) {
    trackEvent({ category, action, label, value });
  }

  _handleEvent(eventType, object) {
    try {
      // identify();
      switch (eventType) {
        case 'addVariantToCart':
          this._trackEvent('product', 'addVariantToCart', (object || {}).id || null);
          break;
        case 'updateQuantity':
          this._trackEvent('product', 'updateQuantity', (object || {}).id || null);
          break;
        case 'openModal':
          this._trackEvent('product', 'openModal', (object || {}).id || null);
          break;
        case 'openOnlineStore':
          this._trackEvent('product', 'openOnlineStore', (object || {}).id || null);
          break;
        case 'openCheckout':
          this._trackEvent('product', 'openCheckout', (object || {}).id || null);
          break;
        case 'openCheckout':
          this._trackEvent('cart', 'openCheckout', (object || {}).id || null);
          break;
        case 'updateItemQuantity':
          this._trackEvent('cart', 'updateItemQuantity', (object || {}).id || null);
          break;
        case 'loadNextPage':
          this._trackEvent('productSet', 'loadNextPage', (object || {}).id || null);
          break;
        case 'closeModal':
          // modal
          this._trackEvent('modal', 'closeModal', (object || {}).id || null);
          break;
        default:
          // noop
          break;
      }
    }
    catch (err) {
      console.debug('Stampr Marketplace failed to handle event', err.stack || err, { eventType, object });
    }
  }

  configure(options = {}) {
    if (this._configured) {
      throw new Error('configure has already run and cannot be run again.  are you mixing simple and custom implementations?');
    }
    this._configured = this._configured || true;
    this.configuration.import(options);
  }

  detectComponentTypeFromElement(element) {
    switch (element.nodeName) {
      case 'STAMPR-PRODUCT':
        return 'product';
      case 'STAMPR-PRODUCTSET':
        return 'productSet';
      case 'STAMPR-COLLECTION':
        return 'collection';
      default:
        return null;
    }
  }

  createComponent(element, options) {
    // to simplify, we just bind all events regardless of the component
    let mergedOptions = deepAssign(this.configuration.export(options), {
      product: {
        events: {
          addVariantToCart:     this._handleEvent.bind(this, 'addVariantToCart'),
          updateQuantity:       this._handleEvent.bind(this, 'updateQuantity'),
          openModal:            this._handleEvent.bind(this, 'openModal'),
          openOnlineStore:      this._handleEvent.bind(this, 'openOnlineStore'),
          openCheckout:         this._handleEvent.bind(this, 'openCheckout'),
        },
      },
      cart: {
        events: {
          openCheckout:         this._handleEvent.bind(this, 'openCheckout'),
          updateItemQuantity:   this._handleEvent.bind(this, 'updateItemQuantity'),
        },
      },
      productSet: {
        events: {
          loadNextPage:         this._handleEvent.bind(this, 'loadNextPage'),
        },
      },
      modal: {
        events: {
          // modal
          closeModal:           this._handleEvent.bind(this, 'closeModal'),
        },
      },
    });
    if (!mergedOptions.component) {
      // if no component has been passed, attempt to get it from the element
      // itself. mainly to support stampr-* tags
      mergedOptions.component = this.detectComponentTypeFromElement(element);
    }
    let component = new Component(element, mergedOptions);
    // console.log('app.createComponent', {element, mergedOptions});
    return component;
  }

  // work around not being able to disable this button
  _removeToggleCartButton() {
    let toggle = document.querySelector('.shopify-buy-frame--toggle');
    if (toggle) {
      // removing leads to errors in buybutton
      // toggle.parentNode.removeChild(toggle);
      // so we'll just hide
      toggle.style.display = 'none';
    }
  }

  hasElementRendered(element) {
    return this._renderedElements.indexOf(element) > -1;
  }

  render(element, options) {
    if (!element || !element.nodeName) return null; // ignore incorrect invoke
    // console.log('app.render', {element, options});
    if (this.hasElementRendered(element)) {
      console.debug('Stampr marketplace.js attempting to render an element that was previously rendered.');
      return;
    }
    this._renderedElements.push(element);
    let id        = Date.now();
    let component = this.createComponent(element, options);
    this._components[id] = component;
    component.render();
    return id;
  }
}
