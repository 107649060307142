import camelCase from 'lodash.camelcase';

export const DATA_ATTR_PREFIX = 'data-';
export const ELEMENT_NODE = 1;

export function isElement(element) {
  if (element && element.nodeType && element.nodeType === ELEMENT_NODE) {
    return true;
  }
  else {
    return false;
  }
}

export function dataAttributesToObject(element) {
  if (!isElement(element)) throw new Error('not a valid element');
  if (element.hasAttributes()) {
    let result      = {};
    let attributes  = element.attributes;
    for (let {name,value} of attributes) {
      if (0 === name.indexOf(DATA_ATTR_PREFIX)) {
        let parts     = name.substr(DATA_ATTR_PREFIX.length).split('-'); // data-account, data-product-something-else
        let namespace = parts.shift(); // account, product,
        let path      = parts.join('-'); // '', something-else
        let key       = camelCase(path); // '', somethingElse
        if (0 === key.length) {
          result[namespace] = value;
        }
        else {
          result[namespace] = result[namespace] || {};
          result[namespace][key] = value;
        }
      }
    }
    return result;
  }
  else {
    return {};
  }
}

export function nestedAttributes(element) {
  let result = {};
  element.querySelectorAll('code').forEach(code => {
    let property;
    if (code.classList.contains('data-account')) {
      property = 'account';
    }
    else if (code.classList.contains('data-tenant')) {
      property = 'tenant';
    }
    else if (code.classList.contains('data-domain')) {
      property = 'domain';
    }
    else if (code.classList.contains('data-id')) {
      property = 'id';
    }
    else if (code.classList.contains('data-products')) {
      property = 'products';
    }
    else if (code.classList.contains('data-collection')) {
      property = 'collection';
    }
    if (property) {
      result[property] = code.innerHTML.trim();
    }
  });
  return result;
}
