import BuyButton from 'buy-button-js';
import pick from 'lodash.pick';

import { COMPONENT_PRODUCT, COMPONENT_PRODUCTSET, COMPONENT_COLLECTION, COMPONENT_TYPES } from '../lib/buy-components.js';

const APP_ID = 6;

export default class Component {
  constructor(element, options = {}) {
    this.element = element;
    this.options = options;
    let accessToken = `${options.account}:${options.tenant}`;
    this._client = BuyButton.buildClient({
      accessToken,
      storefrontAccessToken:  accessToken,
      domain:                 options.domain,
      appId:                  APP_ID,
    });
    this._ui = BuyButton.UI.init(this._client);
    this._component = null; // the reference to the rendered component
  }

  getIdByComponent() {
    switch (this.options.component) {
      case COMPONENT_PRODUCT:
        return this.options.id;
      case COMPONENT_PRODUCTSET:
        return Array.isArray(this.options.products) ? this.options.products : this.options.products.split(/\s*,\s*/g);
      case COMPONENT_COLLECTION:
        return this.options.collection;
      default:
        throw new Error('invalid component type');
    }
  }

  getBuyButtonOptions() {
    let id    = this.getIdByComponent();
    let node  = this.element;
    let options = pick(this.options, [
      'product',
      'cart',
      'modal',
      'productSet',
      'toggle',
      'modalProduct',
      'option',
      'lineItem',
    ]);
    return { id, node, options };
  }

  render() {
    if (!this.options.component) throw new Error('must specify component type');
    if (COMPONENT_TYPES.indexOf(this.options.component) < 0) throw new Error('invalid component type');
    let component   = this.options.component;
    let options     = this.getBuyButtonOptions();
    // console.info('rendering component', component, options);
    this._component = this._ui.createComponent(component, options);
  }
}
