import { COMPONENT_PRODUCT, COMPONENT_PRODUCTSET, COMPONENT_COLLECTION } from './lib/buy-components.js';
import { dataAttributesToObject, nestedAttributes } from './lib/html-attributes.js';
import { isValidOptions } from './lib/component-options.js';

export function findSimpleElements(document, selector) {
  return document.querySelectorAll(selector);
}

export function detectComponentFromOptions(options) {
  if (options.hasOwnProperty('products')) {
    return COMPONENT_PRODUCTSET;
  }
  else if (options.hasOwnProperty('collection')) {
    return COMPONENT_COLLECTION;
  }
  else {
    return COMPONENT_PRODUCT;
  }
}

export function getComponentIdForElement(element) {
  if (!element || !element.nodeName) return null;
  switch (element.nodeName.toLowerCase()) {
    case 'stampr-product':
      return COMPONENT_PRODUCT;
    case 'stampr-productset':
      return COMPONENT_PRODUCTSET;
    case 'stampr-collection':
      return COMPONENT_COLLECTION;
    default:
      return null;
  }
}

export function renderSimpleElements(app, elements) {
  elements.forEach((element, index) => {
    // skip elements rendered manually
    if (app.hasElementRendered(element)) return;
    // both <div data-...> and <div><code class="data..."> supported here with data attrs taking precedence
    let options = Object.assign(nestedAttributes(element), dataAttributesToObject(element));
    if (!options.component) {
      const component = getComponentIdForElement(element);
      if (component) {
        options.component = component;
      }
      else {
        options.component = detectComponentFromOptions(options);
      }
    }
    element.innerHTML = ''; // clear out in case of <code> impl
    if (isValidOptions(options)) {
      app.render(element, options);
    }
    else {
      console.warn('Stampr marketplace.js misconfigured', element);
    }
  });
}
