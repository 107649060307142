import { COMPONENT_PRODUCT, COMPONENT_PRODUCTSET, COMPONENT_COLLECTION } from '../lib/buy-components.js';

export function isValidProductOptions(options) {
  return options && 'id' in options && options.id.length > 0;
}

export function isValidProductSetOptions(options) {
  return options && 'products' in options;
}

export function isValidCollectionOptions(options) {
  return options && 'collection' in options && options.collection.length > 0;
}

export function isValidOptions(options) {
  if (!options) {
    return false;
  }
  if (typeof options !== 'object') {
    return false;
  }
  if (!('component' in options)) {
    return false;
  }
  switch (options.component) {
    case COMPONENT_PRODUCT:
      return isValidProductOptions(options);
    case COMPONENT_PRODUCTSET:
      return isValidProductSetOptions(options);
    case COMPONENT_COLLECTION:
      return isValidCollectionOptions(options);
    default:
      return false; // not a valid component
  }
}
